/* eslint-disable no-use-before-define */

import { debounce, map, prop } from './util';
import { matchesBreakpoint } from './responsive';
import { onDocumentLoaded } from './ready';

const OVERLAY_SELECTOR = '.js-overlay';
const OVERLAY_IMAGE_SELECTOR = '.js-overlay-image';
const OVERLAY_CLOSE_SELECTOR = '.js-overlay-close';

const SLIDE_CONTAINER_SELECTOR = '.js-slide-container';
const SLIDE_MASK_SELECTOR = '.js-slide-mask';
const SLIDE_SELECTOR = '.js-slide';

const ACC_WIDTH_ATTRIBUTE = 'data-acc-width';
const DIRECTION_ATTRIBUTE = 'data-direction';
const BREAKOUT_ATTRIBUTE = 'data-breakout';

/**
 * Image Slider (partially taken from Stedelijk Museum)
 */
const Slider = container => {
  let position = 0;

  const overlay = container.querySelector(OVERLAY_SELECTOR);
  const overlayImage = container.querySelector(OVERLAY_IMAGE_SELECTOR);
  const overlayClose = container.querySelector(OVERLAY_CLOSE_SELECTOR);

  const slideContainer = container.querySelector(SLIDE_CONTAINER_SELECTOR);
  const slideMask = container.querySelector(SLIDE_MASK_SELECTOR);
  const slides = slideMask.querySelectorAll(SLIDE_SELECTOR);

  const getNavButtons = () => {
    return container.querySelectorAll(`button[${DIRECTION_ATTRIBUTE}]`);
  };

  /**
   * Create handler for the overlay, making them removable when it's closed.
   */
  const createOverlayHandlers = () => {
    const escapeHandler = e => {
      if ((e.key && e.key === 'Escape') || e.keyCode === 27) {
        closeOverlay();
      }
    };
    const arrowHandler = e => {
      // if (position <= 0 || position >= slides.length) {
      //   return;
      // }
      if ((e.key && e.key === 'Left') || e.keyCode === 37) {
        position += position > 0 ? -1 : 0;
        slideActions();
      } else if ((e.key && e.key === 'Right') || e.keyCode === 39) {
        position += position < slides.length - 1 ? 1 : 0;
        slideActions();
      }
    };
    return { escapeHandler, arrowHandler };
  };

  const overlayHandlers = createOverlayHandlers();

  const addListener = (action, handler) => document.addEventListener(action, handler);
  const removeListener = (action, handler) => document.removeEventListener(action, handler);

  const isShowingOverlay = () => overlay.getAttribute('aria-hidden') !== 'true';

  /**
   * Close the overlay.
   */
  const closeOverlay = () => {
    overlay.setAttribute('aria-hidden', 'true');
    removeListener('keyup', overlayHandlers.escapeHandler);
    removeListener('keyup', overlayHandlers.arrowHandler);
  };

  /**
   * Open the overlay.
   */
  const openOverlay = () => {
    overlay.setAttribute('aria-hidden', 'false');
    addListener('keyup', overlayHandlers.escapeHandler);
    addListener('keyup', overlayHandlers.arrowHandler);
  };

  /**
   * Show the full size image from a slide to the overlay
   */
  const showSlideInOverlay = slide => {
    const image = slide.querySelector('img');
    overlayImage.innerHTML = image.outerHTML;
    overlayImage.querySelector('img').setAttribute('sizes', '75vw');
    openOverlay();
  };

  /**
   * Determine accumulated widths of slides & set attribute
   */
  const setSlideWidth = () => {
    const slideWidths = map(prop('offsetWidth'), [...slides]);
    const accumulatedWidths = slideWidths.reduce((acc, value) => {
      acc.push(acc[acc.length - 1] + value);
      return acc;
    }, [0]);

    map((slide, i) => {
      slide.setAttribute(ACC_WIDTH_ATTRIBUTE, accumulatedWidths[i]);
    }, [...slides]);
  };

  /**
   * Apply aria hidden true when slide is not active
   */
  const toggleAriaAttributes = () => {
    map((slide, i) => {
      slide.setAttribute('aria-hidden', i !== position);
      slideMask.setAttribute('aria-activedescendant', `${container.id}-item-${position}`);
    }, [...slides]);
  };

  /**
   * Returns amount of whitespace that accumulated on the right side of the screen,
   * when there are no slides left to fill it.
   */
  const getRestWhitespace = pos => {
    const rest = [...slides].slice(pos);
    const restWidth = rest.reduce((total, cur) => cur.offsetWidth + total, 0);
    const sliderRect = slideContainer.getBoundingClientRect();
    const sliderOffset = sliderRect.right - sliderRect.left;
    const winWidth = document.documentElement.clientWidth;
    const diff = winWidth - sliderOffset;
    return restWidth < (winWidth - diff)
      ? (winWidth - diff) - restWidth
      : 0;
  };

  /**
   * Check if remaining slides are available. Note that the position will
   * never reach the slide count, since it will be aligned right. Therefore
   * we allow the overlay to increment until the final count is reached.
   */
  const restSlidesAvailable = pos => {
    if (isShowingOverlay()) {
      return pos + 1 < slides.length;
    }
    return getRestWhitespace(pos) === 0;
  };

  /**
   * Disable nav buttons when no prev of next slide is available (to disable focus)
   */
  const toggleNavButtons = buttons => {
    const prevButtons = [...buttons].filter(
      button => button.getAttribute(DIRECTION_ATTRIBUTE) === 'prev'
    );
    const nextButtons = [...buttons].filter(
      button => button.getAttribute(DIRECTION_ATTRIBUTE) === 'next'
    );
    if (position === 0) {
      prevButtons.map(button => button.setAttribute('disabled', 'disabled'));
    }
    if (position > 0) {
      prevButtons.map(button => button.removeAttribute('disabled'));
    }
    if (restSlidesAvailable(position)) {
      nextButtons.map(button => button.removeAttribute('disabled'));
    } else {
      nextButtons.map(button => button.setAttribute('disabled', 'disabled'));
    }
  };

  /**
   * Update position of slidermask on nav click
   */
  const transitionSlides = () => {
    const width = parseInt([...slides][position].getAttribute(ACC_WIDTH_ATTRIBUTE), 10);
    const delta = getRestWhitespace(position);
    slideMask.style.transform = `translate3d(-${width - delta}px, 0, 0)`;
    if (isShowingOverlay()) {
      showSlideInOverlay(slides[position]);
    }
  };

  const slideActions = () => {
    transitionSlides();
    toggleNavButtons([...getNavButtons()]);
    toggleAriaAttributes();
  };

  /**
   * Set click listener on all slider nav buttons
   */
  const addNavClickListener = buttons => button => {
    button.addEventListener('click', e => {
      const action = button.getAttribute(DIRECTION_ATTRIBUTE) === 'prev' ? -1 : 1;
      position += parseInt(action, 10);
      slideActions();
    });
    button.addEventListener('mouseup', e => {
      button.blur();
    });
  };

  /**
   * Set click listener on all slider item anchor buttons
   */
  const addSlideClickListener = slidesArray => slide => {
    const anchor = slide.querySelector('a');
    anchor.addEventListener('click', e => {
      e.preventDefault();
      showSlideInOverlay(slide);
    });
  };

  /**
   * Set click listener on all slider item anchor buttons
   */
  const addSlideFocusListener = slidesArray => slide => {
    const anchor = slide.querySelector('a');
    anchor.addEventListener('focus', e => {
      window.setTimeout(() => {
        position = slidesArray.findIndex(current => current === slide);
        slideActions();
      }, 100);
    });
  };

  /**
   * A nasty solution to make the images non-clickable on non-overlay sizes
   */
  const removeAnchors = () => {
    const anchors = slideContainer.querySelectorAll('a');
    [...anchors].forEach(anchor => {
      anchor.removeAttribute('href');
      anchor.removeAttribute('target');
    });
  };

  const setBreakoutWidth = () => {
    if (container.getAttribute(BREAKOUT_ATTRIBUTE) !== 'true') {
      return;
    }
    const sliderWidth = slideMask.offsetWidth;
    const winWidth = document.documentElement.clientWidth;
    const diff = winWidth - sliderWidth;
    slideContainer.style.marginRight = `-${diff / 2}px`;
  };

  const resizeActions = () => {
    setBreakoutWidth();
    setSlideWidth();
    transitionSlides();
  };

  const init = () => {
    setBreakoutWidth();
    setSlideWidth();
    toggleAriaAttributes();
    toggleNavButtons([...getNavButtons()]);

    map(addNavClickListener([...getNavButtons()]), [...getNavButtons()]);
    map(addSlideClickListener([...slides]), [...slides]);
    map(addSlideFocusListener([...slides]), [...slides]);

    window.addEventListener('resize', debounce(resizeActions, 500));
    overlayClose.addEventListener('click', e => {
      e.preventDefault();
      closeOverlay();
    });
  };

  return {
    init,
    removeAnchors,
    setBreakoutWidth,
  };
};

export const enhancer = container => {
  const slider = Slider(container);

  if (Modernizr.touchevents && !matchesBreakpoint('large')) {
    slider.removeAnchors();
    slider.setBreakoutWidth();
    return;
  }

  // Wait until all images are loaded before doing calculations.
  onDocumentLoaded(slider.init);
};
