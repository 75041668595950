import Headroom from 'headroom.js';

export const enhancer = header => {
  const headroom = new Headroom(header, {
    offset: header.offsetHeight,
    tolerance: {
      down: 0,
      up: 10,
    },
    classes: {
      initial: 'is-loaded',
      pinned: 'is-pinned',
      unpinned: 'is-unpinned',
      bottom: 'is-at-bottom',
      top: 'is-at-top',
      notTop: 'is-not-at-top',
      notBottom: 'is-not-at-bottom',
    },
  });
  headroom.init();
};
