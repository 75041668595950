import { map } from './util';


// eslintIgnore: disable-all
document.addEventListener("DOMContentLoaded", function() { 
  
  var textBlocks = document.getElementsByClassName('text-block-highlight');
  var flex = document.getElementsByClassName('flexible-content')[0];
  if(textBlocks.length > 0) {
      var wrap = document.createElement("div");
      wrap.classList.add('highlight-blocks-wrap');
      
      var cont = document.createElement("div");
      cont.classList.add("highlight-blocks-container");
      
      cont.appendChild(wrap);

      textBlocks[0].parentNode.insertBefore(cont, textBlocks[0]);

      for(var i = 0; i < textBlocks.length; i++)
      {
          if(textBlocks[i].getElementsByTagName('h2').length > 0) {
              wrap.appendChild(textBlocks[i]);
          }
      }
  }
    
  var charLimit = 270;
		
  var numberOfToggled = document.getElementsByClassName('toggledText');
  var i =0;
  for(i=0; i<numberOfToggled.length; i++){
    
    var el = numberOfToggled[i];
    var elText = el.innerHTML.trim();
    var locale = document.querySelector("meta[property='og:locale']").getAttribute("content");

    if(elText.length > charLimit){
      var showStr = elText.slice(0,charLimit);
      var hideStr = elText.slice(charLimit);
      el.innerHTML = showStr + '<span class="morePoints">...</span> <span class="trimmed">' + hideStr + '</span>';
      if(locale == 'nl_NL') {

        el.parentElement.innerHTML = el.parentElement.innerHTML + "<div class='read-more-review'><a href='#' class='more-review-nl'></a>";
      } else {

        el.parentElement.innerHTML = el.parentElement.innerHTML + "<div class='read-more-review'><a href='#' class='more-review-en'></a>";
      }
    }
    
  }

  window.onclick = function(event) {
    if (event.target.className == 'more-review-nl' || event.target.className == 'more-review-en') {
      event.preventDefault();
      event.target.parentElement.parentElement.classList.toggle('showAll');
    
    }
  }

  //GRAVITY FORMS      
  const form = document.getElementsByClassName('form-container')[0];
  
  if(typeof form !== 'undefined') {
    const inputs = form.querySelectorAll('input');

    // Label animation
    const onFocusEvent = event => {
      const fieldNode = event.currentTarget.parentNode.parentNode;
      fieldNode.setAttribute('data-state', 'focused');
    };

    const onBlurEvent = event => {
      const inputValue = event.currentTarget.value;
      const fieldNode = event.currentTarget.parentNode.parentNode;
      fieldNode.removeAttribute('data-state', 'focused');
      if (inputValue) {
        fieldNode.setAttribute('data-state', 'filled');
      } else {
        fieldNode.removeAttribute('data-state', 'filled');
      }
    };

    map(input => {
      input.addEventListener('focus', onFocusEvent);
      input.addEventListener('blur', onBlurEvent);
    }, inputs);

    // Autofill Address
    const FETCH_PARAMS = {
      method: 'post',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    const triggerEvent = (el, type) => {
      const e = document.createEvent('HTMLEvents');
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    };

    const maybeFetchAddress = (f) => e => {
      const apiEndpoint = 'https://groenschilders.nl/wp-json/postcode-api/v1/fetch_address';
      const zipcodeInput = form.querySelector('.form-postcode input');
      const housenumberInput = form.querySelector('.form-housenumber input');
      const addressInput = form.querySelector('.form-address input');
      const cityInput = form.querySelector('.form-city input');

      if (zipcodeInput.value && housenumberInput.value) {
        const data = {
          zipcode: zipcodeInput.value,
          housenumber: housenumberInput.value,
        };
        fetch(
          apiEndpoint,
          { ...FETCH_PARAMS, body: JSON.stringify(data) }
        ).then(response => {
          if (!response.ok) {
            return response.json().then(result => {
              console.log(result, 'fail');
            });
          }
          return response.json().then(result => {
            // what if no address is found
            if (result.length === 0) {
              return;
            }
            const address = result[0];
            addressInput.value = address.street;
            cityInput.value = address.city.label;
            triggerEvent(addressInput, 'blur');
            triggerEvent(cityInput, 'blur');
            addressInput.disabled = false;
            cityInput.disabled = false;
          });
        });
      }
    };

    const zipcodeInput = form.querySelector('.form-postcode input');
    const housenumberInput = form.querySelector('.form-housenumber input');
    const addressInput = form.querySelector('.form-address input');
    const cityInput = form.querySelector('.form-city input');
    cityInput.disabled = true;
    addressInput.disabled = true;
    [zipcodeInput, housenumberInput].map(input => {
      input.addEventListener('change', maybeFetchAddress(form));
    });
  }  
});
