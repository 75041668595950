import { map } from './util';

const setFilled = input => {
  if (input.value) {
    input.parentNode.setAttribute('data-state', 'filled');
  }
};

const resetInputs = inputs => event => {
  map(input => {
    input.parentNode.removeAttribute('data-state');
  }, inputs);
};

const onFocusEvent = event => {
  const fieldNode = event.currentTarget.parentNode;
  fieldNode.setAttribute('data-state', 'focused');
};

const onBlurEvent = event => {
  const inputValue = event.currentTarget.value;
  const fieldNode = event.currentTarget.parentNode;
  fieldNode.removeAttribute('data-state', 'focused');
  if (inputValue) {
    fieldNode.setAttribute('data-state', 'filled');
  } else {
    fieldNode.removeAttribute('data-state', 'filled');
  }
};

export const enhancer = form => {
  const inputs = form.querySelectorAll('[data-animated-label="true"] input');

  map(setFilled, inputs);

  form.addEventListener('reset', resetInputs(inputs));

  map(input => {
    input.addEventListener('focus', onFocusEvent);
    input.addEventListener('blur', onBlurEvent);
  }, inputs);
};
