import { uuid } from "@grrr/utils";

const FETCH_PARAMS = {
  method: "post",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

/**
 * Checks that an element has a non-empty `name`.
 * @param  {Element} element  the element to check
 * @return {Bool}             true if the element is an input, false if not
 */
const isValidElement = (element) => {
  return element.name;
};

/**
 * Checks if an element’s value can be saved (e.g. not an unselected checkbox).
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the value should be added, false if not
 */
const isValidValue = (element) => {
  return !["checkbox", "radio"].includes(element.type) || element.checked;
};

const isCheckbox = (element) => element.type === "checkbox";
const isFileUpload = (element) => element.type === "file";

function validateFiles(element) {
  let valid = true;
  for (var i = 0; i < element.files.length; i++) {
    cleanFileInputFromErrors(element);
    if (element.files[i].size >= 4194304)  { /* 4MB limit */
      valid = false;

      if (element.errorNode) {
        element.errorNode.remove();
      }
      const field = element.parentNode;
      const errorMessage = 'Bestand overschrijft limiet van 4MB';
      const errorNode = document.createElement('span');
      const errorId = `error-${uuid()}`;
      errorNode.id = errorId;
      errorNode.innerHTML = errorMessage;
      errorNode.setAttribute('role', 'alert');
      element.errorNode = errorNode;
      element.setAttribute('aria-invalid', true);
      element.setAttribute('aria-describedby', errorId);
      field.appendChild(errorNode);

      break;
    } 
  }

  return valid;
}

var elementExists = document.getElementById("images");
if (elementExists) {
  document.getElementById('images').onchange = function(){
    var files = document.getElementById('images');
    validateFiles(files);
  }
}

const cleanFileInputFromErrors = input => {
  if (input.errorNode) {
    input.errorNode.remove();
  }
  input.removeAttribute('aria-invalid');
  input.removeAttribute('aria-describedby');
};

/*
const isMultiSelect = element => element.options && element.multiple;
*/

/**
 * Some input names for multiselection needs [] at the end of the name for
 * a normal post request. But with for javascript fetch we need to remove
 * these brackets for the JSON payload.
 */
const removeArrayNotation = (string) => {
  return string.replace("[]", "");
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

var tmp = [];
const formToJSON = (form) =>
  [].reduce.call(
    form.elements,
    (data, element) => {
      if (isValidElement(element) && isValidValue(element)) {
        tmp['images'] = "";
        /*
         * Some fields allow for more than one value, so we need to check if this
         * is one of those fields and, if so, store the values as an array.
         */
        if (isCheckbox(element)) {
          data[removeArrayNotation(element.name)] = (
            data[removeArrayNotation(element.name)] || []
          ).concat(element.value);
        } else if (isFileUpload(element) && validateFiles(element)){
            for (var i = 0; i < element.files.length; i++) {
              var file = element.files[i];

              getBase64(file).then(
                imageData => {
                
                  tmp['images'] += String(imageData) + "|"
                }
              );
            }
        } else {
          data[element.name] = element.value;
        }
      }
      return data;
    },
    {}
  );

const createAlertElement = (form) => {
  const el = document.createElement("span");
  const alertId = `alert-${uuid()}`;
  el.id = alertId;
  el.classList.add("form-alert");
  el.setAttribute("role", "alert");
  el.setAttribute("aria-hidden", "true");
  form.setAttribute("aria-describedby", alertId);
  form.appendChild(el);
  return el;
};

const Form = (form) => {
  const alertElement = createAlertElement(form);
  const successUrl = form.getAttribute("data-success-url");
  const button = form.querySelector('[type="submit"]');
  const honeypotInput = form.querySelector("input[data-nocomplete]");

  const enableButton = () => {
    button.disabled = false;
  };
  const disableButton = () => {
    button.disabled = true;
  };

  const hideAlert = () => {
    form.removeAttribute("aria-describedby");
    form.removeAttribute("aria-invalid");
    alertElement.setAttribute("aria-hidden", "true");
  };

  const showAlert = (message, type) => {
    if (type === "error") {
      form.setAttribute("aria-invalid", "true");
      form.setAttribute("aria-describedby", alertElement.id);
    }
    alertElement.setAttribute("aria-hidden", "false");
    alertElement.setAttribute("data-type", type);
    alertElement.textContent = message;
  };

  const pause = ms => new Promise(resolve => setTimeout(resolve, ms));

  const getData = async () => {
    let dataElement = await JSON.stringify(await formToJSON(form));
    await pause(10);
    if (tmp['images'] != '') {
      let images_base64 = tmp['images'].split("|")
      
      let imagesJson = ", \"images\":[";
      for (let i = 0; i < images_base64.length-1; i++) {
        let img_base64 = images_base64[i]
        imagesJson += "\""+String(img_base64)+"\""
        if ((i+1) != images_base64.length-1) {
          imagesJson += ","
        } else {
          imagesJson += "]}";
        }
      } 
      dataElement = dataElement.slice(0, -1) + imagesJson
    }
    await pause(10)
    return dataElement;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    hideAlert();
    disableButton();
    if (honeypotInput) {
      honeypotInput.value = "";
    }
    
 
    await fetch(form.getAttribute("action"), {
      ...FETCH_PARAMS,
      body: await getData(),
    }).then((response) => {
      if (!response.ok) {
        enableButton();
        return response
          .json()
          .then((result) => {
            if (window.grecaptcha) {
              grecaptcha.reset();
            }
            showAlert(result.message, "error");
          })
          .catch((error) => {
            showAlert(error, "error");
          });
      }
      if (successUrl) {
        window.location.href = successUrl;
        return undefined;
      }
      enableButton();
      return response
        .json()
        .then((result) => {
          
          showAlert(result, "success");
          form.reset();
        })
        .catch((error) => {
          showAlert(error, "error");
        });
    });
    
  };

  return {
    init: () => form.addEventListener("submit", handleSubmit),
  };
};

export const enhancer = (form) => {
  const formInstance = Form(form);
  formInstance.init();
};
