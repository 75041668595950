import SweetScroll from 'sweet-scroll';

const hasFixedHeader = () => {
  const header = document.querySelector('.js-site-header');
  return header.getAttribute('data-is-fixed') === 'true';
};

export const handler = (el, e) => {
  const target = document.querySelector(el.getAttribute('href'));
  if (!target) {
    return;
  }
  e.preventDefault();

  const scroller = new SweetScroll({
    header: hasFixedHeader() ? '.js-site-header' : '',
  });
  scroller.toElement(target);
};
