export const enhancer = container => {
  const button = container.querySelector('[role="button"]');
  const popup = container.querySelector('.js-popup');
  const closeButton = container.querySelector('.js-popup-close');

  const togglePopup = () => {
    popup.setAttribute('aria-hidden', popup.getAttribute('aria-hidden') === 'false');
    button.setAttribute('aria-expanded', popup.getAttribute('aria-hidden') === 'false');
    button.blur();
  };

  button.addEventListener('click', e => {
    e.preventDefault();
    togglePopup();
  });

  if (closeButton) {
    closeButton.addEventListener('click', e => {
      e.preventDefault();
      togglePopup();
    });
  }
};
